.App {
  text-align: center;
  letter-spacing: 2px;
}

.App-container {
  padding-top: 61.5px;
  max-width: 720px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 44px;
  @media only screen and (max-width: 760px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.container-lg {
  max-width: 768px;
  margin: 0px auto;
  @media only screen and (max-width: 768px) {
    padding: 0 20px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0px;
}

h1 {
  font-weight: 200;
  font-size: 120px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

h2 {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 42px;
  @media only screen and (max-width: 760px) {
    font-size: 34px;
  }
}

h3 {
  font-weight: 800;
  font-size: 16px;
  letter-spacing: 4px;
  @media only screen and (max-width: 760px) {
    font-size: 14px;
  }
}

p {
  font-weight: 800;
  font-size: 16px;
  @media only screen and (max-width: 760px) {
    font-size: 14px;
  }
}

h4 {
  font-weight: 800;
  font-size: 14px;
  letter-spacing: 4px;
  @media only screen and (max-width: 640px) {
    font-size: 12px;
  }
}

h6 {
  font-weight: 800;
  font-size: 18px;
  @media only screen and (max-width: 640px) {
    font-size: 16px;
  }
}

h5 {
  font-weight: 800;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 6px;
  @media only screen and (max-width: 760px) {
    font-size: 16px;
  }
}

a {
  font-size: 20px;
}

.home-container {
  display: flex;
  flex-direction: column;
  gap: 44px;
  @media only screen and (max-width: 760px) {
    gap: 22px;
  }
}
